import { Injectable } from '@angular/core';
import { JobUpdateNotification } from '@core/models/job-updates-notification.model';
import { FirebaseService } from '@core/services/firebase/firebase.service';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Timestamp } from 'firebase/firestore';
import { catchError, of, Subject, takeUntil, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { JobUpdateStateModel } from './job-updates-state.model';
import {
  ClearJobUpdates,
  ListenForJobUpdates,
  SetCurrentTransactionId,
  StopListeningForJobUpdates,
  UpdateJobUpdates,
} from './job-updates.actions';

const defaults: JobUpdateStateModel = {
  currentTransactionId: null,
  jobRefId: null,
  jobUpdates: [],
};

export const JOB_UPDATES_STATE_TOKEN = new StateToken<JobUpdateStateModel>('jobUpdates');

@State<JobUpdateStateModel>({
  name: JOB_UPDATES_STATE_TOKEN,
  defaults,
})
@Injectable()
export class JobUpdatesState {
  private jobUpdatesUnSubscription = new Subject<void>();
  constructor(
    private store: Store,
    private readonly firebaseService: FirebaseService
  ) {}

  @Selector()
  static getJobUpdates(state: JobUpdateStateModel) {
    return state.jobUpdates;
  }

  @Selector()
  static getYouAreHereUpdates(state: JobUpdateStateModel) {
    return state.jobUpdates
      .filter(update => update.data['entityType'] === 'YouAreHere')
      .sort((a, b) => {
        return a.timestamp - b.timestamp;
      })[0];
  }

  @Selector()
  static getLatestJobUpdates(state: JobUpdateStateModel) {
    return state.jobUpdates.length > 0 ? state.jobUpdates[state.jobUpdates.length - 1] : null;
  }

  @Selector()
  static getCurrentTransactionId(state: JobUpdateStateModel) {
    return state.currentTransactionId;
  }

  @Action(SetCurrentTransactionId)
  setCurrentTransactionId(ctx: StateContext<JobUpdateStateModel>, { transactionId }: SetCurrentTransactionId) {
    const { currentTransactionId } = ctx.getState();
    if (!currentTransactionId) {
      ctx.patchState({ currentTransactionId: transactionId ?? uuidv4() });
    }
  }

  @Action(UpdateJobUpdates)
  updateJobUpdates(ctx: StateContext<JobUpdateStateModel>, { jobUpdates }: UpdateJobUpdates) {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const existingJobUpdatesSize = ctx.getState().jobUpdates.length;
    const newJobUpdatesSize = jobUpdates.length;

    if (newJobUpdatesSize >= 4) {
      ctx.patchState({ jobUpdates });
    } else {
      ctx.patchState({
        jobUpdates: [...ctx.getState().jobUpdates.slice(newJobUpdatesSize - existingJobUpdatesSize), ...jobUpdates],
      });
    }
  }

  @Action(ClearJobUpdates)
  clearJobUpdates(ctx: StateContext<JobUpdateStateModel>) {
    ctx.patchState(defaults);
  }

  @Action(ListenForJobUpdates)
  listenForJobUpdates(ctx: StateContext<JobUpdateStateModel>, { orgId, jobRefId, transactionId }: ListenForJobUpdates) {
    const { jobRefId: currentJobRefId, currentTransactionId } = ctx.getState();

    const {
      user: { orgId: currentOrgId },
    } = this.store.selectSnapshot(s => s.auth);

    console.log('listenForJobUpdates ', orgId ?? currentOrgId, jobRefId, transactionId ?? currentTransactionId);

    if (currentJobRefId !== jobRefId) {
      if (currentJobRefId) {
        this.jobUpdatesUnSubscription.next();
        this.jobUpdatesUnSubscription.complete();
      }
      ctx.patchState({ jobRefId });
    } else {
      console.log('jobRefId is the same as the current jobRefId, returning');
      return;
    }

    this.jobUpdatesUnSubscription = new Subject<void>();
    console.log('orgId: ', orgId ?? currentOrgId);
    console.log('jobRefId: ', jobRefId);
    console.log('transactionId: ', transactionId ?? currentTransactionId);
    this.firebaseService
      .getJobUpdates(orgId ?? currentOrgId, jobRefId, transactionId ?? currentTransactionId)
      .pipe(
        takeUntil(this.jobUpdatesUnSubscription),
        tap(updates => {
          const jobUpdates: JobUpdateNotification[] = updates.map(update => {
            const data = update.payload.doc.data();
            const obj = {
              data,
              entityType: data['entityType'],
              operation: data['operation'],
              orgId: data['orgId'],
              jobRefId: data['jobRefId'],
              transactionId: data['transactionId'],
              timestamp: (data['timestamp'] as Timestamp).toMillis(),
            };
            return obj;
          });
          if (jobUpdates.length > 0) {
            this.store.dispatch(new UpdateJobUpdates(jobUpdates));
          }
        }),
        catchError(error => {
          console.error('Error listening for job updates', error);
          return of(null);
        })
      )
      .subscribe(
        () => console.log('job updates subscription'),
        error => console.error('Error listening for job updates', error),
        () => console.log('job updates subscription complete')
      );
  }

  @Action(StopListeningForJobUpdates)
  stopListeningForJobUpdates(ctx: StateContext<JobUpdateStateModel>) {
    this.jobUpdatesUnSubscription.next();
    this.jobUpdatesUnSubscription.complete();
    console.log('stopListeningForJobUpdates');
  }
}
