<div class="n-card" [ngClass]="displayColorClass">
  <div class="n-card-content">
    <div class="n-icon-container">
      <mat-icon>{{ displayIcon }}</mat-icon>
    </div>
    <div class="n-text-container">
      <div class="n-title">{{ data.title }}</div>
      <div class="n-description">{{ data.body }}</div>
    </div>
    <button *ngIf="showViewBtn" mat-button class="n-view-btn" (click)="view()">View</button>
    <button mat-button class="n-close-btn" (click)="dismiss()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
