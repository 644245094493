<div class="collaborators-panel-container">
  <div class="header">
    <div>Collaborators - Coming Soon !</div>
  </div>
  <div class="coming-soon-container">
    <div class="image-container">
      <img height="50" src="assets/icons/coming_soon.png" alt="Coming Soon" />
    </div>
    <div class="coming-soon-text">Coming Soon !</div>
    <div class="coming-soon-description">
      Soon, You'll Be Able To Invite Team Members, Partners, And Collaborators To Work With You On Dwara.One. Stay Tuned
      As We Build This Exciting Feature !
    </div>
  </div>
</div>
