import { SubscriptionChannels } from '@shared/components/notification-drawer/enums/subscription-channels.enum';
import { NotificationSnackBarConfigModel } from '@shared/components/notification-drawer/models/snack-bar-config.model';
import { NotificationType } from '@shared/components/notification-drawer/types/notification.type';

export const ADMIN_SCOPE = 'admin:super';
// purpose related constants
export const FUMIGATION = 'PPS-FMG';
export const STOPOVER = 'PPS-STPOVR';
// attachment related constants
export const UPLOAD_FILE_MAXIMUM_SIZE = 20971520;
export const MINIMUM_IMAGE_HEIGHT = 100;
export const MINIMUM_IMAGE_WIDTH = 200;
export const ATTACH_IMAGE = 'Attach';
export const RE_ATTACH_IMAGE = 'Re-Attach';
export const RE_UPLOAD_IMAGE = 'Re-Upload';
export const IMAGE_DIMENSION_VALIDATION = '100x200 or larger Recommended';
export const FILE_MAX_SIZE_EXCEED = 'file is too large';
// vertical related constants
export const COMMON_VERTICAL = 'VERT-CMN';
export const CUSTOMS_HOUSE_BROKERAGE = 'VERT-CHB';
export const TRANSPORT_VERTICAL = 'VERT-TRN';
export const FREIGHT_FORWARDING_VERTICAL = 'VERT-FFW';
// workflow
export const LOCAL_HAULAGE_REPO = 'LF-RP-YP';
export const LOCAL_HAULAGE_DI = 'LF-DI-FTL';
export const LOCAL_HAULAGE_REMOVAL = 'LF-RM-PY';
export const LOCAL_HAULAGE_RELOCATION = 'LF-CRL';
export const FIELD_SERVICES = 'FS-FA';
export const LOCAL_HAULAGE = 'LF-RL';
export const DEFAULT_RESOURCE = 'UNKNOWN';
export const DISTRIBUTION_WF = 'LF-DI-FTL';
export const FOOT_TO_METER_MULTIPLIER = 0.3048;

// Pagination related constants
export const DEFAULT_LIMIT = 10;
export const DEFAULT_OFFSET = 0;

// Search field related constants
export const DEBOUNCE_TIME = 300;
export const HIGH_DEBOUNCE_TIME = 2000;
export const THROTTLE_TIME = 500;

// Special flags for number data types
export const MASKED_FLAG = -1;

export const MAX_TEXT_LENGTH = 250;

export const GET_FULL_ORG_DETAILS_FOR_GRAPHQL_QUERY = `
      _id
      companyRegNum
      companyWebSite
      createdBy
      epfNumber
      etfNumber
      logo
      managedBy
      operationIds
      opsConfig {
        currencies {
          _id
          code
          createdBy
          currency
          shortCode
          updatedBy
        }
        defaultCurrency {
          _id
          code
          createdBy
          currency
          shortCode
          updatedBy
        }
        defaultLanguage {
          _id
          createdBy
          isoCode
          name
          updatedBy
          writingDirection
        }
        defaultPaymentMethod {
          paymentMethod {
            _id
            createdBy
            logo
            name
            updatedBy
          }
        }
        languages {
          _id
          createdBy
          isoCode
          name
          updatedBy
          writingDirection
        }
        paymentMethods {
          paymentMethod {
            _id
            createdBy
            logo
            name
            updatedBy
          }
        }
      }
      organizationFunctionalityType
      orgName
      orgRegisteredType
      orgSize
      orgType
      paidCustomer
      parentOrgId
      parentOrgName
      party
      defaultBOILocationId
      principalContact {
        designation
        division
        email
        faxNumber
        firstName
        gender
        lastName
        mobile
        nic
        orgUserId
        officeContact {
          ext
          phoneNumber
        }
      }

      registeredAddress {
        addressLine
        city
        country
        state
        timeZone {
          label
          tzCode
          name
          utc
        }
        zipCode
      }

      shortName
      status
      tenant
      tickerCode
      tinNum
      updatedBy
      vatNum
      verticals
      purposes {
        purposeId
        description
      }
      phoneNumbers
      faxNumbers
`;

// Notification related constants

export const NOTIFICATION_CONFIG: Record<NotificationType, NotificationSnackBarConfigModel> = {
  warning: { colorClass: 'n-notification-red', icon: 'warning' },
  info: { colorClass: 'n-notification-yellow', icon: 'info' },
  success: { colorClass: 'n-notification-green', icon: 'check_circle' },
  textMessage: { colorClass: 'n-notification-purple', icon: 'sms' },
};

export const NOTIFICATION_CATEGORIES = [
  { key: SubscriptionChannels.HIGH_PRIORITY, label: 'High Priority' },
  { key: SubscriptionChannels.JOB_RELATED, label: 'Job Related' },
  { key: SubscriptionChannels.OTHER, label: 'Other' },
];
