import { EntityMap } from '@shared/types/entity-map.type';

export type StringFormatter = (
  urlParams: string[],
  queryParams?: EntityMap<string, string | number | boolean>
) => string;

export const formatter = (apiString: string): { format: StringFormatter } => {
  const format = (urlParams: string[], queryParams?: EntityMap<string, string | number | boolean>): string => {
    let url = urlParams.reduce((prev: string, param: string, index: number) => {
      return prev.replace(`{${index}}`, param);
    }, apiString);

    if (queryParams) {
      const queryString = Object.keys(queryParams)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
        .join('&');
      url += `?${queryString}`;
    }
    return url;
  };

  return { format };
};
