import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAX_TEXT_LENGTH } from '@configs/constants';

@Component({
  selector: 'hmt-reject-dialog',
  templateUrl: './reject-dialog.component.html',
  styleUrl: './reject-dialog.component.scss',
})
export class RejectDialogComponent {
  rejectionReasonControl = new FormControl('', [Validators.required, Validators.maxLength(MAX_TEXT_LENGTH)]);

  constructor(
    private readonly dialogRef: MatDialogRef<RejectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { bodyText: string; buttonText: string }
  ) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onReject(): void {
    if (this.rejectionReasonControl.invalid) {
      this.rejectionReasonControl.markAsTouched();
      return;
    }
    this.dialogRef.close(this.rejectionReasonControl.value);
  }
}
