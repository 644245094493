import { UserOperation } from './app.model';
export class SetOperations {
  static readonly type = '[App] Set Operations';
}

export class SetCurrentOperation {
  static readonly type = '[App] Set Current Operation';
  constructor(public currentOperation: UserOperation) {}
}

export class SetRecentStep {
  static readonly type = '[dashboard] set recentStep';

  constructor(public jobRefId: string) {}
}

export class SetYouAreHere {
  static readonly type = '[App] Set You Are Here';
  constructor(
    public workFlowId: string,
    public jobRefId: string,
    public orgId: string
  ) {}
}
