<div class="job-file-panel-container">
  <div class="content-container">
    <div class="header">
      <div>Job Files</div>
      <!-- TODO: remove disabled when backend is ready -->
      <button mat-flat-button class="create-job-file-btn" disabled="true" (click)="createJobFile()">
        Create<mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="search-bar">
      <mat-icon class="search-icon">search</mat-icon>
      <input type="text" placeholder="Search Job Files" [formControl]="searchControl" />
    </div>
    <div class="job-file-card-container">
      <div *ngIf="isLoading$ | async" class="loading-indicator">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
      <div *ngIf="(isLoading$ | async) === false">
        <div
          tabIndex
          class="job-file-card"
          *ngFor="let job of jobFiles$ | async"
          (click)="navigateToJobFile()"
          (keydown.enter)="navigateToJobFile()"
          tabindex="0"
          type="button">
          <div class="job-file-card-content">
            <div class="job-file-card-title" [matTooltip]="job.title" matTooltipPosition="right">
              {{ formatTitle(job.title) }}
            </div>
            <div class="job-file-card-created-at">{{ job.createdAt | date }}</div>
          </div>
          <div class="status" [ngClass]="getStatusColor(job.status)">{{ job.status | capitalize }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="pagination-container">
    <hmt-paginator
      [arrowsDark]="false"
      [disabled]="isLoading$ | async"
      [currentPage]="currentPage"
      [totalPages]="totalPages$ | async"
      (pageChange)="onPageChange($event)"></hmt-paginator>
  </div>
</div>
