import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NOTIFICATION_CONFIG } from '@configs/constants';
import { SnackBarNotificationModel } from '@shared/components/notification-drawer/models/snack-bar.model';
import { NotificationType } from '@shared/components/notification-drawer/types/notification.type';
import { NotificationComponent } from '@shared/components/notification/notification.component';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  readonly defaultSnackBarConfig: MatSnackBarConfig = {
    duration: 4000,
    horizontalPosition: 'end',
    panelClass: 'custom-container',
    verticalPosition: 'top',
  };

  constructor(private snackBar: MatSnackBar) {}

  showSnackBarAlert(title: string, body: string, type: NotificationType, url?: string): void {
    const notificationPayload: SnackBarNotificationModel = {
      title,
      body,
      type: NOTIFICATION_CONFIG[type],
      url,
    };
    this.snackBar.openFromComponent(NotificationComponent, {
      ...this.defaultSnackBarConfig,
      data: notificationPayload,
    });
  }

  showSuccess(body: string, url?: string) {
    this.showSnackBarAlert('Success', body, 'success', url);
  }

  showError(body: string, url?: string) {
    this.showSnackBarAlert('Error', body, 'warning', url);
  }

  showInfo(title: string, body: string, url?: string) {
    this.showSnackBarAlert(title, body, 'info', url);
  }
}
