import { Component, signal } from '@angular/core';

@Component({
  selector: 'hmt-collaborators-panel',
  templateUrl: './collaborators-panel.component.html',
  styleUrl: './collaborators-panel.component.scss',
})
export class CollaboratorsPanelComponent {
  readonly panelOpenState = signal(true);
}
