import { JobUpdateNotification } from '@core/models/job-updates-notification.model';

export const JOB_UPDATES_ACTION_NAME_PREFIX = '[Job Updates]';

export class SetCurrentTransactionId {
  static readonly type = `${JOB_UPDATES_ACTION_NAME_PREFIX} Set Current Transaction Id`;
  constructor(public transactionId?: string) {}
}

export class UpdateJobUpdates {
  static readonly type = `${JOB_UPDATES_ACTION_NAME_PREFIX} Update Job Updates`;
  constructor(public jobUpdates: JobUpdateNotification[]) {}
}

export class ClearJobUpdates {
  static readonly type = `${JOB_UPDATES_ACTION_NAME_PREFIX} Clear Job Updates`;
}

export class ListenForJobUpdates {
  static readonly type = `${JOB_UPDATES_ACTION_NAME_PREFIX} Listen For Job Updates`;
  constructor(
    public jobRefId: string,
    public orgId?: string,
    public transactionId?: string
  ) {}
}

export class StopListeningForJobUpdates {
  static readonly type = `${JOB_UPDATES_ACTION_NAME_PREFIX} Stop Listening For Job Updates`;
}
