export const SHARED_STATE_ACTION_PREFIX = '[Cost State]';

export class SetSpinner {
  static readonly type = `${SHARED_STATE_ACTION_PREFIX} Set Spinner`;
  constructor(
    public isEnable: boolean,
    public message?: string
  ) {}
}

export class ResetSharedState {
  static readonly type = `${SHARED_STATE_ACTION_PREFIX} Reset State`;
}
