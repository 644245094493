import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertDialogType } from '@shared/enums/alert-dialog-type.enum';
import { ConfirmDialog } from '@shared/models/confirm-dialog.model';

@Component({
  selector: 'hmt-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent implements OnInit {
  title: string;
  message: string;
  type: AlertDialogType;
  alertOnly: boolean;

  constructor(
    public dialogAlertRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialog
  ) {
    this.title = data.title;
    this.message = data.message;
    this.type = data.type;
    this.alertOnly = data.alertOnly;
  }

  @Output() closeClick = new EventEmitter();
  ngOnInit(): void {}

  onConfirm(): void {
    this.dialogAlertRef.close(true);
  }

  onDismiss(): void {
    this.dialogAlertRef.close(false);
  }

  onOk() {
    this.dialogAlertRef.close(false);
  }
}
