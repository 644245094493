<div class="dialog-container">
  <div class="cancel-button-container">
    <div class="header-text header-title"><span>Are You Sure?</span></div>
    <button mat-icon-button class="close-icon" (click)="onCancel()"><mat-icon>close</mat-icon></button>
  </div>
  <div class="header-container">
    <div class="header-text header-subtitle">{{ data.bodyText }}</div>
  </div>
  <div class="text-area-container">
    <mat-form-field subscriptSizing="dynamic" appearance="outline" class="full-width">
      <mat-label>Specify the reason for rejection</mat-label>
      <textarea
        class="dense-2"
        matInput
        placeholder="Enter reason for rejection"
        [formControl]="rejectionReasonControl"
        maxlength="250"
        rows="5"></textarea>
      <mat-hint>{{ rejectionReasonControl.value?.length || 0 }}/250</mat-hint>
      <mat-error *ngIf="rejectionReasonControl.invalid">
        Please enter a reason for rejection (max 250 characters).
      </mat-error>
    </mat-form-field>
  </div>
  <div class="footer">
    <button mat-stroked-button class="btn-grey-outline" (click)="onCancel()">Cancel</button>
    <button mat-flat-button class="btn-purple-fill" (click)="onReject()">{{ data.buttonText }}</button>
  </div>
</div>
