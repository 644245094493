import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { User } from '@core/models/user.model';
import { mergeMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(private firestore: AngularFirestore) {}

  getJobUpdates(orgId: string, jobRefId: string, transactionId: string) {
    const date = new Date();
    console.log(`job-updates/orgs/${orgId}/jobs/${jobRefId}/transactions/${transactionId} and date is ${date}`);
    return (
      this.firestore
        .collection(`job-updates`)
        .doc('orgs')
        .collection(orgId)
        .doc('jobs')
        .collection(jobRefId, ref => ref.where('timestamp', '>=', date))
        // .doc('transactions')
        // .collection(transactionId, ref => ref.where('timestamp', '>=', date))
        // .collection(transactionId)
        .stateChanges(['added', 'modified', 'removed'])
    );
  }

  getEntityUpdates(orgId: string, entityId: string) {
    const date = new Date();
    console.log(`entity-updates/orgs/${orgId}/entities/${entityId} and date is ${date}`);
    return (
      this.firestore
        .collection(`entity-updates`)
        .doc('orgs')
        .collection(orgId)
        .doc('entities')
        .collection(entityId, ref => ref.where('timestamp', '>=', date))
        // .doc('transactions')
        // .collection(transactionId, ref => ref.where('timestamp', '>=', date))
        // .collection(transactionId)
        .stateChanges(['added', 'modified', 'removed'])
    );
  }

  getNotifications(orgId: string, jobRefId: string) {
    const date = new Date();
    return this.firestore
      .collection(orgId)
      .doc('JOB_UPDATES')
      .collection(jobRefId, ref => ref.where('createdAt', '>=', date))
      .stateChanges();
    // .snapshotChanges();
  }

  fetchMoreNotifications(notificationLimit: number, user: User) {
    const first = this.firestore
      .collection(user.orgId)
      .doc('PERSONAL_NOTIFICATIONS')
      .collection(user.userId, ref => ref.orderBy('createdAt').limit(notificationLimit));
    return first.get().pipe(
      take(1),
      mergeMap(documentSnapshots => {
        const lastOne = documentSnapshots.docs[documentSnapshots.docs.length - 1];
        const x = this.firestore
          .collection(user.orgId)
          .doc('PERSONAL_NOTIFICATIONS')
          .collection(user.userId, ref => ref.orderBy('createdAt', 'desc').startAfter(lastOne).limit(notificationLimit))
          .snapshotChanges();
        return x;
      })
    );
  }

  getInitialNotifications(notificationLimit: number, user: User) {
    return this.firestore
      .collection(user.orgId)
      .doc('PERSONAL_NOTIFICATIONS')
      .collection(user.userId, ref => ref.orderBy('createdAt', 'desc').limit(notificationLimit))
      .stateChanges();
  }
}
