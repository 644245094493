import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { FormlyFieldConfig, FormlyFormOptions, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';

@Component({
  selector: 'app-dialog-container',
  standalone: true,
  imports: [
    CommonModule,
    FormlyMaterialModule,
    FormlyModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    FormlyMatDatepickerModule,
    MatDialogModule,
  ],
  templateUrl: './dialog-container.component.html',
  styleUrl: './dialog-container.component.scss',
})
export class DialogContainerComponent {
  fields: FormlyFieldConfig[] = [];
  options: FormlyFormOptions = {};

  model: any = {};
  form: FormGroup;
  @Output() submitModel = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<DialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.model = data.model ?? {};
    this.fields = data.fields;
    this.form = data?.form ?? new FormGroup({});
    this.options = data?.options ?? {};
  }

  submit() {
    this.submitModel.emit(this.model);
    if (this.form.valid) {
      this.dialogRef.close(this.model);
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
