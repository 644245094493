import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GraphqlClientService {
  constructor(private apollo: Apollo) {}

  mutation<T, V extends Record<string, unknown>>(query: string, variables: V = {} as V): Observable<T> {
    return this.apollo
      .mutate<T>({
        mutation: gql`
          ${query}
        `,
        variables,
      })
      .pipe(map(result => result.data as T));
  }

  query<T, V extends Record<string, unknown>>(query: string, variables: V = {} as V): Observable<T> {
    return this.apollo
      .query<T>({
        query: gql`
          ${query}
        `,
        variables,
      })
      .pipe(map(result => result.data as T));
  }
}
