export const environment = {
  production: false,
  developer: 'Haulmatic',
  version: 2.2,
  haulmatic: {
    baseUrl: 'https://dev.api.dwara.one',
    apiGateway: 'https://dev.api.dwara.one' + '/api/graphql-gateway',
    // apiGateway: 'http://localhost:8080' + '/api/graphql-gateway',
  },
  google: {
    apiKey: 'AIzaSyBhHe3jP_tu9VkVCGZxVAVZW6bfWzcCiUY',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDJb8Q1P1_CExeYZQFWL5jiJzeqfj8WtxY',
    authDomain: 'hmt-platform-dev.firebaseapp.com',
    projectId: 'hmt-platform-dev',
    storageBucket: 'hmt-platform-dev.appspot.com',
    messagingSenderId: '234371977444',
    appId: '1:234371977444:web:2b04453678d2ae745f5cf1',
    measurementId: 'G-D2HJSTKN0D',
  },
};
