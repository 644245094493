/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { AlertDialogComponent } from '@shared/components/alert-dialog/alert-dialog.component';
import { DialogContainerComponent } from '@shared/components/alert-dialog/dialog-container/dialog-container.component';
import { AlertDialogType } from '@shared/enums/alert-dialog-type.enum';

@Injectable({
  providedIn: 'root',
})
export class DialogHandlerService {
  readonly defaultAlertDialogConfig = {
    height: '21%',
    width: '30%',
  };
  readonly defaultDialogConfig = {
    height: '100%',
    position: { right: '0px', top: '0px' },
  };

  constructor(public matDialog: MatDialog) {}

  /**
   * @description - Open Dialog for confirmation, warning, success and errors
   * @param title - Title of the dialog
   * @param message - Message or Question that we want show
   * @param type - Type of the Dialog AlertDialogType
   * @param config - Other configuration that we want to parse, if not Default options will be used
   */
  openConfirmationDialog(
    title: string,
    message: string,
    type: AlertDialogType,
    alertOnly: boolean = false,
    config: MatDialogConfig = this.defaultAlertDialogConfig
  ): MatDialogRef<AlertDialogComponent> {
    return this.matDialog.open(AlertDialogComponent, {
      ...this.defaultAlertDialogConfig,
      ...config,
      data: { title, message, type, alertOnly },
    });
  }

  /**
   * @description Open Material Dialog, parse data and listen to close events
   * @param component - component that we need to parse
   * @param data - data that we need to parse to the model
   * @param config - dialog configs, if empty default configs will be used
   */
  openDialog(
    component: ComponentType<any>,
    data = {},
    config: MatDialogConfig = this.defaultDialogConfig
  ): MatDialogRef<any> {
    return this.matDialog.open(component, { ...this.defaultDialogConfig, ...config, data });
  }

  openSchemaDialog(
    fields: FormlyFieldConfig[],
    model?: any,
    form?: FormGroup,
    options?: FormlyFormOptions,
    config: MatDialogConfig = this.defaultDialogConfig
  ) {
    return this.matDialog.open(DialogContainerComponent, {
      ...this.defaultDialogConfig,
      ...config,
      data: { fields, model, form, options },
    });
  }

  openSchemaAlert(fields: FormlyFieldConfig[], config: MatDialogConfig = this.defaultAlertDialogConfig, model?: any) {
    return this.matDialog.open(DialogContainerComponent, {
      ...this.defaultAlertDialogConfig,
      ...config,
      data: { fields, model },
    });
  }
}
