import { Component, input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { DEBOUNCE_TIME, DEFAULT_OFFSET, THROTTLE_TIME } from '@configs/constants';
import { AuthState } from '@core/store/auth/auth.state';
import { Select, Store } from '@ngxs/store';
import { JobFileSummary } from 'app/modules/wizard/models/job-file-summary.type';
import { JobFilePanelState, PageInfo } from 'app/modules/wizard/store/job-file-panel/job-file-panel.state';
import { debounceTime, Observable, Subject, takeUntil, tap, throttleTime } from 'rxjs';

@Component({
  selector: 'hmt-workflow-panel',
  templateUrl: './workflow-panel.component.html',
  styleUrl: './workflow-panel.component.scss',
})
export class WorkflowPanelComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private readonly LIMIT: number = 8;

  items = input<JobFileSummary[]>();

  @Select(JobFilePanelState.jobFiles) jobFiles$: Observable<JobFileSummary[] | []>;
  @Select(JobFilePanelState.pageInfo) pageInfo$: Observable<PageInfo | null>;
  @Select(JobFilePanelState.isLoading) isLoading$: Observable<boolean>;
  @Select(JobFilePanelState.getTotalPages) totalPages$: Observable<number>;

  currentPage: number = 1;
  searchControl: FormControl;
  orgId: string;

  constructor(
    private readonly store: Store,
    private fb: FormBuilder
  ) {
    this.searchControl = fb.control('');
  }

  ngOnInit(): void {
    this.orgId = this.store.selectSnapshot(AuthState.getOrgId);
    this.loadInitialJobFiles();
    this.listenToSearchInput();
  }

  loadInitialJobFiles() {}

  listenToSearchInput() {
    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(DEBOUNCE_TIME),
        throttleTime(THROTTLE_TIME),
        tap(searchStr => {
          this.currentPage = 1;
          this.performSearch(searchStr, DEFAULT_OFFSET);
        })
      )
      .subscribe();
  }

  performSearch(searchStr: string, offset: number) {}

  onPageChange(page: number): void {
    this.currentPage = page;
    const searchStr = this.searchControl.value;
    this.performSearch(searchStr, this.currentPage);
  }

  getStatusColor(status: string) {
    if (status) return status.toLowerCase();
    return 'common';
  }

  navigateToJobFile() {}

  formatTitle(title: string) {
    const maxCharLen = 25;
    if (title.length > maxCharLen) {
      return title.substring(0, maxCharLen) + '...';
    }
    return title;
  }

  // TODO: Implement creating a job file from the jobfile panel
  createJobFile() {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
