import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusToLower',
})
export class StatusToLowerPipe implements PipeTransform {
  transform(value: string, joinChar: string = '-'): string {
    if (!value) return value;

    if (value.includes('_')) {
      const splittedValues: string[] = value.split('_');
      return splittedValues
        .map(splittedValue => splittedValue.charAt(0).toUpperCase() + splittedValue.slice(1).toLowerCase())
        .join(joinChar);
    }

    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  }
}
