import { checkAllOfScopes, checkOneOfScopes } from '@core/guards/auth.guard';

const ORGS = 'orgs';
const PARTNER_ORGS = 'partner_orgs';
const CLUSTERS = 'clusters';
const OPERATIONS = 'operations';
const HUMAN_RESOURCES = 'human_resources';
const VEHICLES = 'vehicles';
const LOCATIONS = 'locations';
const DOCUMENTS = 'documents';

const JOB_FILES = 'job_files';
const TENDERS = 'tenders';
const BIDS = 'bids';
const PROCUREMENT_CONTRACTS = 'procurement_contracts';
const SALES_CONTRACTS = 'sales_contracts';

type ScopePrefixes =
  | typeof ORGS
  | typeof PARTNER_ORGS
  | typeof CLUSTERS
  | typeof OPERATIONS
  | typeof HUMAN_RESOURCES
  | typeof VEHICLES
  | typeof LOCATIONS
  | typeof DOCUMENTS
  | typeof JOB_FILES
  | typeof TENDERS
  | typeof BIDS
  | typeof PROCUREMENT_CONTRACTS
  | typeof SALES_CONTRACTS;

const COMMON_ACTIONS = ['read', 'create', 'update', 'delete', 'any'] as const;
const COMMON_ACTIONS_WITHOUT_DELETE = ['read', 'create', 'update', 'any'] as const;

type CommonActions = (typeof COMMON_ACTIONS)[number];
type CapitalizedCommonActions = Uppercase<CommonActions>;

function getScopesWithCommonActions(prefix: ScopePrefixes) {
  return Object.fromEntries(COMMON_ACTIONS.map(action => [action.toUpperCase(), `${prefix}:${action}`])) as Record<
    CapitalizedCommonActions,
    string
  >;
}

function getScopesWithCommonActionsWithoutDelete(prefix: ScopePrefixes) {
  return Object.fromEntries(
    COMMON_ACTIONS_WITHOUT_DELETE.map(action => [action.toUpperCase(), `${prefix}:${action}`])
  ) as Record<CapitalizedCommonActions, string>;
}

export const SCOPES = {
  ORGS: getScopesWithCommonActions(ORGS),
  PARTNER_ORGS: getScopesWithCommonActions(PARTNER_ORGS),
  CLUSTERS: getScopesWithCommonActions(CLUSTERS),
  OPERATIONS: getScopesWithCommonActions(OPERATIONS),
  HUMAN_RESOURCES: getScopesWithCommonActions(HUMAN_RESOURCES),
  VEHICLES: getScopesWithCommonActions(VEHICLES),
  LOCATIONS: getScopesWithCommonActions(LOCATIONS),
  DOCUMENTS: getScopesWithCommonActions(DOCUMENTS),
  JOB_FILES: {
    ...getScopesWithCommonActionsWithoutDelete(JOB_FILES),
    TERMINATE: `${JOB_FILES}:terminate`,
  },
  TENDERS: getScopesWithCommonActionsWithoutDelete(TENDERS),
  BIDS: {
    ...getScopesWithCommonActionsWithoutDelete(BIDS),
    WITHDRAW: `${BIDS}:withdraw`,
    REJECT: `${BIDS}:reject`,
  },
  PROCUREMENT_CONTRACTS: getScopesWithCommonActionsWithoutDelete(PROCUREMENT_CONTRACTS),
  SALES_CONTRACTS: getScopesWithCommonActionsWithoutDelete(SALES_CONTRACTS),

  // FOR HAULMATIC SUPER ADMIN
  ADMIN: { SUPER: 'admin:super' },

  TASK_FEED: {
    TODO: 'task_feed:todo',
    SERVICE_REQUESTS: 'task_feed:service_requests',
  },
} as const;

/**
 * Checks if the user has the required scopes
 * @param user - The user to check
 * @param oneOfScopes - The scopes to check for
 * @returns - True if the user has the required scopes, false otherwise
 */
export function doesHaveOneOfScopes(userScopes: string[], oneOfScopes: string[]) {
  return checkOneOfScopes(userScopes, { oneOf: oneOfScopes });
}

/**
 * Checks if the user has all of the required scopes
 * @param user - The user to check
 * @param allOfScopes - The scopes to check for
 * @returns - True if the user has all of the required scopes, false otherwise
 */
export function doesHaveAllOfScopes(userScopes: string[], allOfScopes: string[]) {
  return checkAllOfScopes(userScopes, { allOf: allOfScopes });
}
