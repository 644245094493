import { Component, Input } from '@angular/core';

@Component({
  selector: 'hmt-key-value',
  templateUrl: './key-value.component.html',
  styleUrl: './key-value.component.scss',
})
export class KeyValueComponent {
  @Input() keyValue: { key: string; value: string } = { key: '--', value: '--' };
}
