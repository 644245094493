const JOB_FILE_PANEL_PREFIX = '[Job File Panel]';

export class FetchJobFileSummaries {
  public static readonly type = `${JOB_FILE_PANEL_PREFIX} Fetch Job File Summaries`;
  constructor(
    public orgId: string,
    public offset: number,
    public limit: number
  ) {}
}

export class SearchJobFilesSummaries {
  static readonly type = `${JOB_FILE_PANEL_PREFIX} Search Job Files Summaries`;
  constructor(
    public orgId: string,
    public searchStr: string,
    public limit: number,
    public offset: number
  ) {}
}
