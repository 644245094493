import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SEARCH_JOB_FILE_SUMMARIES_QUERY } from '@shared/gql-shared-queries';
import { Apollo, gql } from 'apollo-angular';
import { catchError, of, tap } from 'rxjs';
import { JobFileSummary } from '../../models/job-file-summary.type';
import { FetchJobFileSummaries, SearchJobFilesSummaries } from './job-file-panel.actions';

export interface PageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  pageSize: number;
  total: number;
}

export class JobFilePanelStateModel {
  jobFiles: JobFileSummary[];
  activeJobFile: JobFileSummary;
  pageInfo: PageInfo;
  isLoading: boolean;
}

@State<JobFilePanelStateModel>({
  name: 'jobFilePanel',
  defaults: {
    jobFiles: [],
    activeJobFile: null,
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
      pageSize: 10,
      total: 0,
    },
    isLoading: true,
  },
})
@Injectable()
export class JobFilePanelState {
  constructor(private apollo: Apollo) {}

  @Selector()
  static jobFiles(state: JobFilePanelStateModel) {
    return state.jobFiles;
  }

  @Selector()
  static pageInfo(state: JobFilePanelStateModel) {
    return state.pageInfo;
  }

  @Selector()
  static activeJobFile(state: JobFilePanelStateModel) {
    return state.activeJobFile;
  }

  @Selector()
  static isLoading(state: JobFilePanelStateModel) {
    return state.isLoading;
  }

  @Selector()
  static getTotalPages(state: JobFilePanelStateModel) {
    return Math.ceil(state.pageInfo.total / state.pageInfo.pageSize);
  }

  @Action(FetchJobFileSummaries)
  fetchJobFileSummaries(ctx: StateContext<JobFilePanelStateModel>, { orgId, offset, limit }: FetchJobFileSummaries) {
    const GET_JOB_FILES_QUERY = gql`
    query {
      paginatedJobFilesSummary(orgId: "${orgId}", offset: ${offset}, limit: ${limit}) {
        items {
          status
          title
          jobRefId
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          pageSize
          total
        }
      }
    }
  `;
    return this.apollo.query({ query: GET_JOB_FILES_QUERY }).pipe(
      tap(response => {
        ctx.setState({
          jobFiles: response.data['paginatedJobFilesSummary']['items'],
          pageInfo: response.data['paginatedJobFilesSummary']['pageInfo'],
          activeJobFile: null,
          isLoading: false,
        });
      }),
      catchError(error => {
        ctx.patchState({
          isLoading: false,
        });
        return of(error);
      })
    );
  }

  @Action(SearchJobFilesSummaries)
  searchJobFileSummaries(
    ctx: StateContext<JobFilePanelStateModel>,
    { orgId, limit, searchStr, offset }: SearchJobFilesSummaries
  ) {
    const variables = {
      orgId,
      jobFileTitle: searchStr,
      offset,
      limit,
    };

    return this.apollo
      .query({
        query: SEARCH_JOB_FILE_SUMMARIES_QUERY,
        variables,
      })
      .pipe(
        tap(response => {
          ctx.patchState({
            jobFiles: response.data['searchJobFile']['items'],
            pageInfo: response.data['searchJobFile']['pageInfo'],
            isLoading: false,
          });
        }),
        catchError(error => {
          ctx.patchState({
            isLoading: false,
          });
          return of(error);
        })
      );
  }
}
