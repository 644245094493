import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@configs/api-endpoints';
import { RefreshTokenModel } from '@core/models/refresh-token.model';
import { TokenObject } from '@core/models/token-object.model';
import { User } from '@core/models/user.model';
import { SetToken, SetUser } from '@core/store/auth/auth.actions';
import { Store } from '@ngxs/store';
import { Tenant } from 'app/modules/organizations-manager/models/tenant.model';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private cookeService: CookieService,
    private store: Store
  ) {}

  // TODO: refactor this method after completely moving to this app
  login(credentials: { username: string; password: string }): Observable<TokenObject> {
    const url = API.auth.getToken;
    return this.httpClient.post<TokenObject>(url, { userId: credentials.username, password: credentials.password });
  }

  refreshToken(refreshTokenRequest: RefreshTokenModel): Observable<TokenObject> {
    const url = API.auth.refreshToken;
    return this.httpClient.post<TokenObject>(url, refreshTokenRequest);
  }

  getUserByUserId(userId: string): Observable<User> {
    const url = API.auth.getUserByUserId.format([userId]);
    return this.httpClient.get<User>(url);
  }

  private setTokenAndUser(): void {
    // TODO: remove cookie service from here after fully implement the login feature
    const tokenFromCookie = this.cookeService.get('token');
    const userString = localStorage.getItem('currentUser');
    this.store.dispatch(new SetToken(tokenFromCookie));
    if (userString) {
      this.store.dispatch(new SetUser(JSON.parse(userString)));
    }
  }

  getTenants(): Observable<Tenant[]> {
    const url = API.auth.getTenants;
    return this.httpClient.get<Tenant[]>(url);
  }

  initResetPassword(userId: string) {
    const url = API.auth.resetPasswordInit;
    return this.httpClient.post<unknown>(url, { userId });
  }

  public resetPassword(payload: {
    newPassword: string;
    confirmPassword: string;
    resetKey: string;
  }): Observable<unknown> {
    const url = API.auth.resetPassword;
    return this.httpClient.post(url, payload);
  }
}
