import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'hmt-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnChanges {
  @Input() totalPages: number = 0;
  @Input() currentPage: number = 1;
  @Input() disabled: boolean = false;
  @Input() arrowsDark: boolean = false;
  @Input() whiteArrows: boolean = true;
  @Output() pageChange = new EventEmitter<number>();
  pages: number[] = [];
  maxDisplayedPages: number = 4;

  ngOnInit(): void {
    this.updatePages();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalPages'] || changes['currentPage']) {
      this.updatePages();
    }
  }

  updatePages(): void {
    let startPage: number;
    let endPage: number;

    if (this.totalPages <= this.maxDisplayedPages) {
      // Less than maxDisplayedPages total pages so show all pages
      startPage = 1;
      endPage = this.totalPages;
    } else {
      // More than maxDisplayedPages total pages so calculate start and end pages
      const maxPagesBeforeCurrentPage = Math.floor(this.maxDisplayedPages / 2);
      const maxPagesAfterCurrentPage = Math.ceil(this.maxDisplayedPages / 2) - 1;

      if (this.currentPage <= maxPagesBeforeCurrentPage) {
        // Current page near the start
        startPage = 1;
        endPage = this.maxDisplayedPages;
      } else if (this.currentPage + maxPagesAfterCurrentPage >= this.totalPages) {
        // Current page near the end
        startPage = this.totalPages - this.maxDisplayedPages + 1;
        endPage = this.totalPages;
      } else {
        // Current page somewhere in the middle
        startPage = this.currentPage - maxPagesBeforeCurrentPage;
        endPage = this.currentPage + maxPagesAfterCurrentPage;
      }
    }

    this.pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  }

  setPage(page: number): void {
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.updatePages();
      this.pageChange.emit(page);
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatePages();
      this.pageChange.emit(this.currentPage);
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePages();
      this.pageChange.emit(this.currentPage);
    }
  }
}
